.astrict-sign {
  color: var(--astrict-color);
}

.authentication-wrapper {
  padding: 20px 150px 40px 40px;
  background-color: var(--surface-a);
  background-image: url('../../images/login-bg.jpg');
  background-image: url('../../images/login-bg1.jpg');
  background-position: center bottom;
  background-size: cover; 
  @include bp(llg-max) {
    padding-right: 100px;
  }
  @include bp(lg-max) {
    padding: 20px 40px 40px;
  }
  @include bp(sm-max) {
    padding: 20px 15px 40px;
  }
  .dark-theme & {
    background-color: var(--surface-body);
  }
  .tatva-logo-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 300;
    gap: 8px;
    color: var(--heading-text);
    margin-top: 10px;
    .dark-theme & {
      color: var(--title-dark-text);
    }
    img{
      max-width: 90px;
      display: block;
      .dark-theme & {
        display: none;
      }
      &.dark-theme-img {
        display: none;
        .dark-theme & {
          display: block;
        }
      }
    }
  }
  .authentication-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    min-height: calc(100vh - 60px);
    @include bp(lg-max) {
      justify-content: center;
    }
    .login-container {
      max-width: 470px;
      width: 100%;
      background-color: var(--surface-a);
      padding: 50px;
      border-radius: 25px;
      box-shadow: rgba(102, 102, 102, 0.7) 0px 20px 30px -10px;
      @include bp(sm-max) {
        padding: 25px;
      }
      .dark-theme & {
        background-color: var(--dark-boxes);
      }
      .site-logo {
        margin: 0 auto 25px;
        display: block;
        text-align: center;
        @include bp(sm-max) {
          margin-bottom: 24px;
        }
        img {
          width: 190px;
          margin: 0 auto; 
          .dark-theme & {
            display: none;
          }
          &.dark-theme-logo {
            display: none;
            .dark-theme & {
              display: block;
            }
          }
        }
      }
      h2 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: var(--heading-text);
        margin: 0 0 12px;
        text-align: center; 
        @include bp(sm-max) {
          font-size: 20px;
          margin-bottom: 8px;
        }
        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-color);
        margin-bottom: 25px;
        text-align: center;
        @include bp(sm-max) {
          font-size: 14px;
          margin-bottom: 18px;
        }
        .dark-theme & {
          color: var(--title-light-text2);
        }
      }
      form {
        .field {
          &.pwd {
            margin-bottom: 8px;
          }
          .p-inputtext {
            font-weight: 400;
            font-size: 14px;  
            padding-right: 41px; 
            .dark-theme & {
              color: #e4e6f4;
              border-color:  #2c2a40;
            }
            &::-webkit-input-placeholder { 
              .dark-theme & {
                color: #767992;
              }
            }
  
            &:-moz-placeholder { 
              .dark-theme & {
                color: #767992;
              }
            }
  
            &::-moz-placeholder { 
              .dark-theme & {
                color: #767992;
              }
            }
  
            &:-ms-input-placeholder { 
              .dark-theme & {
                color: #767992;
              }
            }
            &.p-invalid {
              &:focus {
                border-color: var(--primary-color);
              }
            }
          }
          .p-password.p-invalid.p-component > .p-inputtext {
            &:focus {
              border-color: var(--primary-color);
            }
          }
          .p-input-icon-right {
            .pi {
              color: var(--black2);
              top: 50%;
              transform: translateY(-50%);
              font-size: 16px;
              right: 16px;
              margin: 0;
              .dark-theme & {
                color: #767992;
              }
            }
          }
          > label span {
            font-weight: 300;
            font-size: 13px;
            line-height: 15px;
            color: var(--heading-text);
            margin-bottom: 5px;
            .dark-theme & {
              color: var(--title-dark-text);
            }
            &.input-focus {
              color: var(--focus-label-color);
            }
          }
        }
        .link-wrapper {
          margin-top: 16px;
          margin-bottom: 16px;
          text-align: right;

          &.back-login {
            text-align: center;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              .pi-angle-left {
                font-size: 18px;
                padding-right: 3px; 
              }
            }
          }
          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: var(--primary-color);
            .dark-theme & {
              color: #9085ff;
            }
          }
        }
        button {
          margin: 0 0 16px;
          font-family: 'Public Sans', sans-serif;
          &.Mui-disabled {
            color: var(--surface-a);
          }
        }
      }
    }
  }
  &.forgot-pwd {
    .authentication-inner {
      .right-col {
        button {
          margin-bottom: 30px;
        }
      }
    }
  }
  &.reset-pwd {
    .authentication-inner {
      .right-col {
        h2 {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.keep-me-sign-in {
  display: flex;
  align-items: center;
  position: relative; 
  .styled-checkbox {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 14px;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 6px;
      display: inline-block;
      vertical-align: text-top;
      width: 17px;
      height: 17px;
      background: white;
      border: 2px solid #ececec;
      border-radius: 4px;
    }  
  
    // Box checked
    &:checked + label:before {
      background: var(--primary-color);
      border-color: var(--primary-color);
    }   
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  } 
}
