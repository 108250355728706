* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $fontSize;
}

body {
  font-family: var(--font-family);
  color: var(--heading-text);
  background-color: var(--surface-h);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.dark-theme {
    background-color: var(--surface-body);
    color: var(--title-dark-text);
  }
}

a,
button {
  text-decoration: none;
  color: var(--primary-color);
}

// .layout-theme-light {
//   background-color: var(--surface-100);
// }
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    background-color: #ffffff;
  }
}

.p-inputswitch {
  width: 2.5rem;
  height: 1.5rem;

  .p-inputswitch-slider {
    &:before {
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      &:before {
        transform: translateX(1rem);
        -webkit-transform: translateX(1rem);
        -moz-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        -o-transform: translateX(1rem);
      }
    }
  }
}

h6.no-underline {
  position: relative;

  &::before {
    content: '';
    background-color: var(--blue-500) !important;
    height: 1.5px;
    width: 0;
    position: absolute;
    top: calc(100% - 1.5px);
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.rbc-month-row {
  min-height: 130px !important;
  overflow: visible !important;
  flex-basis: auto !important;
}

.rbc-event-label {
  display: none !important;
}

.space-between {
  display: flex;
  justify-content: space-between !important;
}

.calender-tooltip {
  background: rgb(255, 255, 255);
  padding: 16px;
  z-index: 1000000;
  font-size: 12px;
  line-height: 20px;
  color: #212121;
  border: 1px solid #ececec;
  border-radius: 8px;
  box-shadow: 0px 4px 30px rgba(58, 54, 68, 0.08);
  font-weight: 500;
  .dark-theme & {
    background: #0e0c23;
    border-color: #0e0c23;
    color: #e4e6f4;
  }
}

.calender-tooltip .tooltip-inner strong {
  min-width: 100px;
  display: inline-block;
  color: #787878;
  font-weight: 400;
  .dark-theme & {
    color: #e4e6f4;
  }
}

.feedback-label {
  width: 100%;
  margin: 0 0 10px 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
}

.copy-icon {
  font-size: 12px !important;
  cursor: pointer !important;
  color: var(--primary-color);
}

.rdtPicker {
  min-width: 100% !important;
}

.filter-margin {
  margin-right: 10px !important;
}

.react-datepicker__input-container {
  input {
    width: 100% !important;
    background: transparent !important;
  }
}
.react-datepicker__tab-loop {
  position: relative;
}
.react-datepicker-popper {
  width: 100% !important;
  transform: none !important;
}

.react-datepicker--time-only,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
  margin: 0 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.candidate-list-email-text {
  font-size: 13px !important;
  margin-top: 3px;
}

.unavailable-interviewer-text-color {
  color: red !important;
}

.pending-badge {
  background-color: #fff1e2 !important;
  color: #333333 !important;
  .dark-theme & {
    background-color: #653c13 !important;
    color: #ffffff !important;
  }
}

.schedule-badge {
  background-color: #defdff !important;
  color: #333333 !important;
  .dark-theme & {
    background-color: #164144 !important;
    color: #ffffff !important;
  }
}

.complete-badge {
  background-color: #e2f7e8 !important;
  color: #333333 !important;
  .dark-theme & {
    background-color: #0e451e !important;
    color: #ffffff !important;
  }
}

.reschedule-badge {
  background-color: #daf2ff !important;
  color: #333333 !important;
  .dark-theme & {
    background-color: #0b435f !important;
    color: #ffffff !important;
  }
}

.cancel-badge {
  background-color: #ffeeef !important;
  color: #333333 !important;
  .dark-theme & {
    background-color: #61161a !important;
    color: #ffffff !important;
  }
}

.blink {
  position: relative;
}

.blink,
.button-inner {
  color: #fff;
  padding: 9px 16px;
  margin-left: 8px;
  margin-right: 16px;
  border: 0;
  box-shadow: 0px 2px 14px rgba(115, 103, 240, 0.2);
  border-radius: 20px;
  font-size: 15px;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  animation: blink 12s linear infinite;
}

.blink .button-inner {
  position: absolute;
  z-index: -1;
  filter: blur(12px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.blink:not(:hover) .button-inner {
  opacity: 0.8;
}

@keyframes blink {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/* Other styles */
.monthly_average .db_text span {
  background: #fff;
  display: inline-block;
  padding: 2px 7px 4px 7px;
  margin: 0 0 0 4px;
  border-radius: 3px;
  color: #cc71a0;
  line-height: 1;
}

/* Box sizing reset */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.full-width {
  width: 100% !important;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  /* Adjust color as needed */
}

.status-container {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

.status-label {
  font-size: 80px;
  color: white;
  text-align: center;
}

.p-tabview-nav-container {
  position: relative !important;
}

.legends {
  position: absolute;
  height: 50px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 5px;

  span {
    display: inline-block !important;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    margin-right: 6px;
  }
}

.legend-label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--heading-text);

  .dark-theme & {
    color: var(--title-dark-text);
  }
}

.pending-label {
  background-color: #fff1e2 !important;
  .dark-theme & {
    background-color: #653c13 !important;
  }
}

.schedule-label {
  background-color: #defdff !important;
  .dark-theme & {
    background-color: #164144 !important;
  }
}

.complete-label {
  background-color: #e2f7e8 !important;
  .dark-theme & {
    background-color: #0e451e !important;
  }
}

.reschedule-label {
  background-color: #daf2ff !important;
  .dark-theme & {
    background-color: #0b435f !important;
  }
}

.cancel-label {
  background-color: #ffeeef !important;
  .dark-theme & {
    background-color: #61161a !important;
  }
}

.interview-status {
  i {
    font-size: 1rem !important;
    margin-left: 2px;
  }
}

.grow-1 {
  flex-grow: 1 !important;
}

.align-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.unread-notification {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff4100;
}

body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
  z-index: 1000001;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 1000001;
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}

.sub-technology {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400 !important;
  display: block;
  margin-bottom: 16px;
  color: var(--heading-text);

  .dark-theme & {
    color: var(--title-dark-text) !important;
  }

  &.hide-label {
    display: none;
  }
}

.dark-mode .rbc-off-range-bg {
  background: var(--surface-body) !important;
}

.dark-mode .rbc-today {
  background: #26225a !important;
}

.dark-mode .rbc-label {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-button-link {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode span[role='columnheader'] {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-btn-group button {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-btn-group button.rbc-active {
  color: #373a3c !important;
}

.dark-mode .rbc-btn-group button:hover {
  color: #373a3c !important;
}

.dark-mode .rbc-toolbar-label {
  color: rgba(228, 230, 244, 1) !important;
}

.dark-mode .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid transparent !important;
}

.dark-mode .rbc-toolbar button:active {
  color: #373a3c !important;
}

.dark-mode .rbc-toolbar button:focus {
  color: #373a3c !important;
}

.dark-theme .p-menu .p-menuitem-link:not(.p-disabled):hover {
  color: var(--title-dark-text) !important;
  background: var(--link-hover-bg) !important;
}

.dark-theme .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--title-dark-text) !important;
}

.rbc-toolbar button,
.react-datepicker,
.rbc-button-link {
  font-family: 'Public Sans', sans-serif !important;
}
.calendar-wrap {
  .rbc-header {
    padding: 3px 3px;
    font-weight: 400;
  }
}

.hr-avatar {
  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
  }

  .user-img {
    font-size: 14px;
    height: 35px;
    width: 35px;
  }
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  .dark-theme & {
    color: var(--input-dark-text) !important;
  }
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  .dark-theme & {
    color: var(--title-dark-text);
  }
}
.react-datepicker {
  border: 0 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  .dark-theme & {
    background: var(--dark-boxes);
  }
  .react-datepicker__header {
    background: #fff;
    border: 0;
    border-radius: 0;
    text-align: left;
    padding: 14px 14px 4px;
    .dark-theme & {
      background: var(--dark-boxes);
    }
    .react-datepicker-time__header {
      font-size: 14px;
      color: var(--heading-text);
      font-weight: 700;
      text-align: left;
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker__triangle {
    display: none;
  }
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto !important;
  padding: 5px 14px !important;
  font-size: 14px;
  color: var(--heading-text);
  text-align: left;
  .dark-theme & {
    color: var(--input-dark-text);
  }
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--gray-400);
  opacity: 0.8;
  max-height: 100px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #7367f026 !important;
  .dark-theme & {
    background: var(--link-hover-bg) !important;
  }
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--primary-color) !important;
  font-weight: 500 !important;
  .dark-theme & {
    background: var(--link-hover-bg) !important;
    color: #e4e6f4;
  }
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  .dark-theme & {
    background-color: var(--link-hover-bg) !important;
  }
}
.react-datepicker__month-container {
  .react-datepicker__current-month {
    font-size: 14px;
    text-align: center;
    color: var(--heading-text);
    padding-bottom: 5px;
    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
  .react-datepicker__header {
    padding: 14px;
    border-bottom: 1px solid #ececec;
    .dark-theme & {
      border-color: var(--border-light-color);
    }
  }
  .react-datepicker__day--selected {
    border-radius: 50% !important;
    background-color: var(--primary-color);
    font-weight: 500;
    &:hover {
      background-color: var(--primary-color);
    }
  }
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50% !important;
}
.react-datepicker__tab-loop {
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 9px;
  }
}
.interview-calender-view {
  .rbc-toolbar {
    font-size: 18px;
    color: #333333;
    font-weight: 400;
    .rbc-btn-group {
      display: flex;
      gap: 5px;
      button {
        border: 1px solid #ececec;
        border-radius: 5px !important;
        padding: 6px 10px;
        .dark-theme & {
          border-color: #2c2a40;
          color: #e4e6f4 !important;
        }
        &:hover,
        &:focus,
        &.rbc-active {
          border-color: #cac6eb;
          background: #e3e0fc;
          .dark-theme & {
            background: #ffffff;
            color: #18162b !important;
            border-color: #ffffff;
          }
        }
      }
    }
  }
}
.rbc-row-segment {
  .rbc-event {
    font-size: 12px;
    font-weight: 300;
    color: #333333;
    border-left: 2px solid transparent;
    border-radius: 4px;
    padding: 3px 6px;
    font-weight: 400;
    .dark-theme & {
      color: #ffffff;
    }
  }
}
.rbc-calendar {
  .rbc-day-slot {
    .rbc-event,
    .rbc-background-event {
      font-size: 12px;
      color: #333333;
      border: 0;
      border-left: 2px solid transparent;
      border-radius: 4px;
      padding: 3px 6px;
      font-weight: 400;
      .dark-theme & {
        color: #ffffff;
      }
    }
  }
}
.rbc-off-range-bg {
  background: var(--surface-h) !important;
}
.notifications-modal {
  ol {
    margin-bottom: 18px;
  }
  ul {
    margin-bottom: 0;
  }
  ol,
  ul {
    padding-left: 0;
    list-style-position: inside;
    font-weight: 200;
    margin-top: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  p {
    margin: 0 0 5px;
  }
}

.candidate-location-other {
  margin-top: 10px;
}
.custom-table-wrapper {
  .MuiChip-colorError {
    padding: 5px 8px;
    background-color: transparent;
    border-radius: 3px;
    margin: 0 4px 6px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    border: none;
    font-family: 'Public Sans', sans-serif;
    height: auto;
    color: var(--surface-a) !important;
    background-color: #bbb !important;
    span {
      padding: 0;
    }
    .dark-theme & {
      background-color: #1d1a3d !important;
    }
  }
  .MuiChip-colorSuccess {
    padding: 5px 8px;
    background-color: transparent;
    border-radius: 3px;
    margin: 0 4px 6px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    border: none;
    font-family: 'Public Sans', sans-serif;
    height: auto;
    color: var(--surface-a) !important;
    background-color: #81c784 !important;
    span {
      padding: 0;
    }
    .dark-theme & {
      background-color: #102f19 !important;
    }
  }
}

.form-row-wrapper {
  .feedback-params {
    .form-col {
      flex: 1 0 25% !important;
      max-width: 100% !important;
    }
  }
}

.feedback-params {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.feedback-params > .form-col {
  flex: 1 0 25% !important; /* This makes each child take up 25% width, allowing 4 per row */
  box-sizing: border-box;
}

/* When there are only 1 or 2 children */
.feedback-params:only-child > .form-col,
.feedback-params:nth-last-child(2):first-child > .form-col,
.feedback-params:nth-last-child(2):first-child ~ .form-col {
  flex-basis: 50% !important; /* Makes children take up 50% width when there are 1 or 2 */
}

/* When there is only 1 child */
.feedback-params:only-child > .form-col {
  flex-basis: 100% !important; /* Makes the child take up 100% width when it's the only one */
}

/* Ensure that no more than 4 children are displayed per row */
.feedback-params > .form-col:nth-child(4) ~ .form-col {
  flex-basis: 25% !important; /* Force any children after the 4th to start on a new row */
}

.margin-bottom {
  margin-bottom: 10px;
}

.justify-flex-end {
  justify-content: end;
}

.hr-name-list {
  height: 250px;
  overflow: auto;
}

.image-preview {
  position: fixed;
  width: 250px;
  height: 300px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
