.button-border-radius {
  border-radius: 24px !important;
}

.p-button {
  box-shadow: none !important;
  &.primary-icon-btn {
    width: 40px;
    max-height: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @include bp(xs-max) {
      width: 30px;
      max-height: 30px;
      min-width: 30px;
      height: 30px;
      line-height: 30px;
    }
    .pi {
      color: var(--text-color);
      font-size: 20px;
      @include bp(xs-max) {
        font-size: 16px;
      }
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }
    &:hover {
      background-color: var(--rgba1) !important;
    }
    &:focus {
      background-color: var(--rgba1) !important;
      box-shadow: none;
    }
    .dark-theme & {
      &:hover {
        background-color: var(--link-hover-bg) !important;
      }
      &:focus {
        background-color: var(--link-hover-bg) !important;
        box-shadow: none;
      }
    }
  }
  &.p-button-danger {
    border-color: var(--inactive-text) !important;
    color: var(--inactive-text) !important;
  }
}
.p-link {
  &:hover {
    background-color: var(--rgba1) !important;
  }
  &:focus {
    background-color: var(--rgba1) !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .dark-theme & {
    &:hover {
      background-color: var(--link-hover-bg) !important;
    }
    &:focus {
      background-color: var(--link-hover-bg) !important;
      box-shadow: none;
    }
  }
}

.MuiButtonBase-root {
  &.MuiButton-root {
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    padding: 5px 15px;
    box-shadow: none !important;
    transition: all 0.5s;
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    &.MuiButton-containedPrimary {
      box-shadow: 0 2px 6px rgba(115, 103, 240, 0.3) !important;
    }
    @include bp(xxs-max) {
      font-size: 12px;
    }
    &[disabled],
    &.Mui-disabled {
      opacity: 0.6;
      cursor: default;
    }
    &.MuiButton-contained {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--primary-color-text);
      &:hover {
        background-color: darken(#7367f0, 5);
        border-color: darken(#7367f0, 5);
      }
    }
    &.gray-btn {
      background-color: var(--white1);
      border-color: var(--white1);
      color: var(--light-gray1);
      &:hover {
        background-color: darken(#f4f4f4, 5);
      }
      &.border-btn {
        background-color: var(--white);
        border: 1px solid var(--light-gray1);
        color: var(--heading-text);
        &:hover {
          background-color: var(--secondary-btn-bg);
        }
        .dark-theme & {
          color: #e4e6f4;
          border-color: #2c2a40;
          &:hover {
            background-color: var(--secondary-btn-bg);
            color: var(--surface-a);
            border-color: var(--secondary-btn-bg);
          }
        }
      }
    }
    &.MuiButton-outlined {
      border-color: var(--primary-color);
      color: var(--primary-color);
      &.red-outlined-btn {
        border-color: var(--inactive-text);
        color: var(--inactive-text);
        padding: 4px;
        min-width: auto;
        height: 45px;
        width: 45px;
      }
    }
  }
}
.primary-link {
  margin: 0;
  padding: 12px 20px;
  color: var(--primary-color) !important;
  font-weight: 600 !important;
  .dark-theme & {
    color: #9085ff !important;
  }
}
