.white-box {
  background-color: var(--surface-a);
  border-radius: 10px;
  // box-shadow: 0 3px 9px 1px rgba(58, 54, 68, 0.03), 0 9px 8px rgb(46, 43, 54, 0.02),
  //   0 1px 6px 4px rgb(48, 46, 57, 0.01);
  box-shadow: 0px 4px 10px 1px rgba(58, 54, 68, 0.03);
  height: 100%;
  padding: 20px;

  .dark-theme & {
    background-color: var(--dark-boxes);
  }

  @include bp(lgl-max) {
    padding: 15px;
  }
}

.white-box-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -24px;

  @include bp(smd-max) {
    margin: 0 -8px -16px;
  }

  &.hr-dashboard {
    .white-box-item {
      &.small-width {
        flex: 0 0 50%;
        max-width: 50%;
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .white-box-item {
          margin: 0;
          &:last-child {
            @include bp(smd-max) {
              padding-bottom: 0 !important;
            }
          }
          &:not(:last-child) {
            @include bp(smd-max) {
              padding-bottom: 16px !important;
            }
          }
        }
      }
    }
  }

  .white-box-item {
    .white-box {
      .chart-container {
        width: auto;
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: #7367f0;
          border-radius: 5px;
        }

        .chart-figure-wrapper {
          min-width: 100%;
        }
      }
    }
    padding: 0 12px 24px;

    @include bp(smd-max) {
      padding: 0 8px 16px;
    }

    @include bp(ssm-max) {
      flex-wrap: wrap;
    }

    .auto-margin {
      .p-chart {
        margin: 0 auto;

        canvas {
          margin: 0 auto;
        }
      }
    }

    &.small-width {
      flex: 0 0 50%;
      max-width: 50%;

      @include bp(llg-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .white-box-item {
        margin: 0 -12px;

        .dashboard-card-item {
          padding: 0 12px;
        }
      }

      .chart-figure-wrapper {
        .p-chart {
          display: flex;
          justify-content: center;

          canvas {
            width: 100%;
          }
        }
      }
    }

    &.half-width {
      flex: 0 0 50%;
      max-width: 50%;

      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &.full-width {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .card-title {
      font-size: 20px;
      line-height: 1.16;
      margin-bottom: 30px;
      font-weight: 400;
      color: var(--heading-text);

      // opacity: 0.87;
      .dark-theme & {
        color: var(--title-dark-text);
        opacity: 1;
      }

      @include bp(lg-max) {
        font-size: 18px;
      }

      @include bp(sm-max) {
        font-size: 16px;
        margin-bottom: 18px;
      }
    }

    .card-title-block {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      flex: 1;
      margin-bottom: 20px;

      @include bp(sm-max) {
        margin-bottom: 15px;
      }

      .card-title-wrapper {
        display: flex;
        align-items: center;
      }

      .equal-height-JlocK {
        @include bp(sm-max) {
          height: inherit !important;
        }

        .custom-target-icon {
          vertical-align: middle;
        }
      }

      &.secondary-title {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .card-title {
        margin: 0;
      }

      .filter-block {
        padding-left: 5px;
        position: relative;

        .filter-btn {
          //   position: absolute;
          margin-top: -10px;
          margin-right: -10px;

          @include bp(lg-max) {
            width: 30px;
            height: 30px;
            min-width: 30px;
            max-width: 30px;
            margin-top: -5px;
          }

          @include bp(sm-max) {
            margin-top: -7px;
          }

          i {
            // margin-top: 2px;
            height: 25px;
            width: 25px;
            display: inline-block;

            @include bp(lg-max) {
              height: 20px;
              width: 20px;
            }

            img {
              max-width: 100%;

              &.default-img {
                display: block;

                .dark-theme & {
                  display: none;
                }
              }

              &.dark-mode-img {
                display: none;

                .dark-theme & {
                  display: block;
                }
              }
            }
          }
        }

        .chartFilterMenu {
          top: 100% !important;
          left: auto !important;
          right: 0 !important;
        }
      }
    }
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

.dashboard-card-list {
  margin: 0 -12px 0;

  @include bp(smd-max) {
    margin: 0 -8px 0;
  }

  @include bp(ssm-max) {
    .left-block-padding {
      padding-right: 0;
      padding-bottom: 12px;
    }

    .dashboard-card-lower-item {
      padding-top: 0 !important;
      padding-bottom: 12px !important;
    }
  }

  .dashboard-card-item {
    flex: 0 0 50%;
    max-width: 50%;

    @include bp(lg-max) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include bp(ssm-max) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 16px !important;

      .left-block-padding {
        padding-right: 0;
        padding-bottom: 12px;
      }
    }

    .card-title-block {
      align-items: flex-start;
    }

    .card-content {
      display: flex;
      justify-content: space-between;
      color: var(--heading-text);

      .icon-block {
        margin: 0;

        i {
          height: 46px;
          width: 46px;
          border-radius: 100%;
          background-color: #F4EDFF;
          padding: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .dark-theme & {
            background-color: #361E5B;
          }

          img {
            max-width: 100%;
          }
          .normal-img {
            display: block;
            .dark-theme & {
              display: none;
            }
          }
          .dark-theme-img {
            display: none;
            .dark-theme & {
              display: block;
            }
          }

          .active-img {
            display: none;
          }

          &.candidate-ic {
            padding: 10px;
            background-color: #E7F4FF;
            .dark-theme & {
              background-color: #0E2D47;
            }
          }
        }
      }

      .content {
        padding-right: 6px;
      }

      .card-title {
        margin: 0;
        font-size: 20px;
        line-height: 1.25;
        color: var(--text-color);

        .dark-theme & {
          color: var(--title-light-text2);
        }
      }

      .number {
        font-size: 34px;
        line-height: 1;
        color: var(--heading-text);
        margin: 0 0 8px;

        .dark-theme & {
          color: var(--title-dark-text);
        }

        @include bp(lg-max) {
          font-size: 18px;
        }
      }

      .status-block {
        display: flex;
        margin: 0 -8px;

        .status-title {
          display: flex;
          align-items: center;
          margin: 0 0 10px;
          color: var(--text-color);
        }

        .user-status {
          // flex: 0 0 50%;
          // max-width: 50%;
          padding: 0 8px;
          text-align: center;

          .status-title {
            font-size: 14px;

            i {
              height: 46px;
              width: 46px;
              padding: 8px;
              background-color: #E2F9F6;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .dark-theme & {
                background-color: #102F19;
              }
            }
          }

          .number {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            padding-left: 3px;
          }

          &.inactive-users {

            // border-left: 1px solid var(--light-gray);
            // .dark-theme & {
            //     border-color: var(--border-light-color);
            // }
            .status-title {
              i {
                background-color: #FFE8E8;
                .dark-theme & {
                  background-color: #3E1D1D;
                }
              }
            }
          }
        }
      }
    }

    .card-title-block {
      margin: 0;

      .card-title {
        margin: 0;
      }
    }

    &.active-item {
      .white-box {
        background-color: var(--primary-color);

        .icon-block {
          i {
            background-color: var(--white-rgba);

            .active-img {
              display: block;
            }

            .default-img {
              display: none;
            }
          }
        }

        .card-title {
          color: var(--surface-a);
          opacity: 1;
        }

        .number {
          color: var(--surface-a);
        }
      }
    }
  }
}

.secondary-card-list {
  .left-block {
    flex: 0 0 25%;
    max-width: 25%;

    @include bp(xxlg-max) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .dashboard-card-item {
      flex: 0 0 100%;
      max-width: 100%;
      height: 50%;

      @include bp(xxlg-max) {
        height: auto;
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include bp(sm-max) {
        flex: 0 0 50%;
        max-width: 50%;
        height: auto;
      }

      @include bp(ssm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .card-content {
        align-items: center;
        height: 100%;
      }

      .card-title-block {
        @include bp(xxlg-min) {
          flex-direction: column-reverse;
        }

        .icon-block {
          @include bp(xxlg-min) {
            margin: 0 0 12px;
          }
        }

        .content {
          @include bp(xxlg-min) {
            padding-left: 5px;
            width: 100%;
          }
        }
      }
    }
  }

  .right-block {
    flex: 0 0 75%;
    max-width: 75%;

    @include bp(xxlg-max) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
    }

    .dashboard-card-item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

// interviewer-dashboard
.interviewer-dashboard-card {
  padding: 0;
  margin-bottom: 24px;

  .white-box {
    border-radius: 0 0 10px 10px;
  }

  .interviewer-table-header {
    border-bottom: 1px solid #dee2e6;
    padding: 15px;

    h5 {
      font-weight: 400;
      color: var(--heading-text);

      @include bp(lg-max) {
        font-size: 18px;
      }

      @include bp(sm-max) {
        font-size: 16px;
      }

      .dark-theme & {
        color: var(--title-dark-text);
      }
    }

    .button-wrapper {
      @include bp(xxs-max) {
        width: 100%;
        margin-top: 8px;
        flex: unset !important;
      }
    }

    .dark-theme & {
      border-bottom-color: var(--border-light-color);
    }

    .p-badge {
      font-size: 14px !important;
      padding: 6px 13px;
      height: 35px;
      width: 35px;
      border-radius: 50% !important;
      line-height: 20px;
      font-weight: 400;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
  }

  .custom-table-wrapper {
    .p-datatable-wrapper {
      min-height: calc(100vh - 580px);
    }
  }
}

.atlwdg-trigger {
  z-index: 1000;
}

.dashboard-card-item-height {
  height: 50% !important;
  padding: 0 !important;
}

.dashboard-card-upper-item {
  padding-bottom: 12px !important;
  @include bp(smd-max) {
    padding-bottom: 8px !important;
  }
  @include bp(ssm-max) {
    padding-bottom: 0px !important;
  }
}

.dashboard-card-lower-item {
  padding-top: 12px !important;
  @include bp(smd-max) {
    padding-top: 8px !important;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.full-width {
  width: 100%;
}

.highlighted-item .p-menuitem-link {
  color: #4338ca;
  background: #eef2ff;
}

.dark-theme .highlighted-item .p-menuitem-link {
  background: var(--link-hover-bg) !important;
  color: var(--title-dark-text) !important;
}

.dark-theme .highlighted-item .p-menuitem-link .p-menuitem-text {
  color: var(--title-dark-text) !important;
}

.dark-theme .popup_menu.action-popup .p-menuitem .p-menuitem-link:hover {
  background: var(--link-hover-bg) !important;
  color: var(--title-dark-text) !important;
}

.dark-theme .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--title-dark-text) !important;
}

.display-flex {
  display: flex;
}
.p-datatable-emptymessage {
  td {
    text-align: center !important;
  }
}
