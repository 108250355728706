.confirm-dialog-wrapper {
  &.p-dialog {
    .p-dialog-header {
      padding: 20px 20px 20px;
    }
    .p-dialog-content {
      padding: 0 20px 20px;
    }
    .p-dialog-footer {
      padding: 20px;
      .p-button {
        .p-button-icon {
          display: none;
        }
      }
    }
  }
  .confirm-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem !important;
  }

  i {
    margin-right: 0.3rem;
    margin-bottom: 0 !important;
  }
}
