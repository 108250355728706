.p-dialog-mask {
  padding: 10px;
}

.p-confirm-dialog {
  max-width: 35%;

  @include bp(smd-max) {
    max-width: 60%;
  }
}

.p-dialog {
  border-radius: 15px;
  background-color: var(--surface-a);
  margin: 0;
  padding: 0;
  max-height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  .dark-theme & {
    background-color: var(--dark-boxes);
    color: var(--title-dark-text);
  }

  .p-dialog-header {
    padding: 20px 20px 10px;
    border-radius: 0;
    background-color: transparent;

    @include bp(smd-max) {
      padding: 15px;
    }

    .p-dialog-title {
      font-size: 18px;
      line-height: 1.22;
      font-family: 'Public Sans', sans-serif;
      font-weight: 600;
      color: var(--heading-text);

      .dark-theme & {
        color: var(--title-dark-text);
      }
    }

    .p-dialog-header-icons {
      .p-dialog-header-close {
        height: 40px;
        width: 40px;
        margin-left: 10px;
        background: transparent !important;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        margin-right: -5px;

        &:hover {
          background: var(--rgba1) !important;
        }

        .dark-theme & {
          &:hover {
            background-color: var(--link-hover-bg) !important;
          }
        }

        span {
          font-weight: 700;
          color: var(--text-color);

          .dark-theme & {
            color: var(--title-dark-text);
          }

          &:hover {
            color: var(--text-color);

            .dark-theme & {
              color: var(--title-dark-text);
            }
          }
        }
      }

      .p-dialog-header-icon {
        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
    }
  }

  .p-dialog-content {
    background-color: transparent;
    color: var(--heading-text);
    padding: 3px 20px 20px;
    overflow-x: hidden;

    @include bp(smd-max) {
      padding: 3px 15px 15px;
    }

    .dark-theme & {
      color: var(--title-dark-text2);
    }

    .pi-exclamation-triangle {
      display: none;
    }

    .pi-exclamation-triangle,
    .pi-question-circle {
      font-size: 26px;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .p-confirm-dialog-message {
      font-size: 14px;
      line-height: 1.6;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .p-dialog-footer {
    border-top: 1px solid var(--light-gray4);
    background-color: transparent;
    padding: 15px 24px;

    .dark-theme & {
      border-color: var(--border-light-color);
    }

    @include bp(smd-max) {
      padding: 15px;
    }
  }

  .p-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--primary-color-text);
    text-transform: uppercase;
    height: 40px !important;
    line-height: 20px;
    padding: 5px 15px;
    box-shadow: none !important;
    transition: all 0.5s;
    font-family: 'Public Sans', sans-serif;
    margin: 0 0 0 12px;
    font-size: 14px;
    letter-spacing: 0.02857em;
    min-width: 40px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px rgba(115, 103, 240, 0.3) !important;

    @include bp(xxs-max) {
      font-size: 12px;
    }

    .p-button-label {
      font-weight: 400;
      transition: all 0.5s;
      flex: none;
    }

    &:first-child {
      margin: 0;
    }

    &:hover {
      background-color: darken(#7367f0, 5);
      border-color: darken(#7367f0, 5);
    }

    &.p-button-text {
      background-color: var(--white);
      border: 1px solid var(--light-gray1);
      color: var(--heading-text);
      box-shadow: none !important;

      &:hover {
        background-color: var(--secondary-btn-bg);
        border-color: var(--light-gray1);
        color: var(--heading-text);
      }

      .dark-theme & {
        border-color: #2c2a40;
        color: var(--primary-color-text);
      }
    }
  }

  .p-tabview {
    .p-tabview-nav-container {
      margin: 0 -24px;
      border-bottom: 1px solid var(--light-gray4);

      .dark-theme & {
        border-color: var(--border-light-color);
      }

      @include bp(smd-max) {
        margin: 0 -15px;
      }

      .p-tabview-nav-content {
        position: relative;
      }
    }

    .p-tabview-nav {
      border: none;

      li {
        .p-tabview-nav-link {
          padding: 13px 24px;
          font-size: 16px;
          line-height: 1.18;
          font-family: 'Public Sans', sans-serif;
          font-weight: 400;
          color: var(--heading-text);
          border: none;
          margin: 0;

          .dark-theme & {
            color: var(--title-dark-text);
          }

          @include bp(smd-max) {
            padding: 13px 15px;
          }
        }

        &.p-highlight {
          .p-tabview-nav-link {
            color: var(--primary-color);
          }
        }
      }
    }

    .p-tabview-panels {
      padding: 37px 0;
    }
  }

  .p-accordion-tab {
    .p-accordion-header {
      a {
        .dark-theme & {
          background: transparent !important;
        }
      }
    }

    .p-accordion-header:hover {
      a {
        .dark-theme & {
          color: #e8e8e8 !important;
          background: #5d5c6b !important;
        }
      }
    }
  }

  .p-accordion-tab-active {
    .p-accordion-header {
      a {
        .dark-theme & {
          color: #fff !important;
        }
      }
    }
  }

  .p-accordion-content {
    .dark-theme & {
      background: transparent !important;
    }
  }

  .p-accordion .p-accordion-header .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .detail-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }

    .title {
      max-width: 185px;
      flex: 0 0 185px;
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.18;
      font-family: 'Public Sans', sans-serif;
      display: inline-flex;
      justify-content: space-between;
      color: #7e7e7e;

      .dark-theme & {
        color: #b4b6ce;
      }

      @include bp(sm-max) {
        font-size: 15px;
      }

      @include bp(xs-max) {
        max-width: 120px;
        flex: 0 0 120px;
      }

      @include bp(xxxs-max) {
        flex: 0 0 110px;
        max-width: 110px;
      }

      .colon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 12px;
      }
    }

    .content {
      flex: 1;
      margin: 0;
      font-size: 14px;
      line-height: 1.18;
      font-family: 'Public Sans', sans-serif;
      color: var(--heading-text);
      word-break: break-all;
      font-weight: 500;
      p {
        &:not(:last-child) {
          margin: 0 0 5px;
        }
      }

      @include bp(sm-max) {
        font-size: 15px;
      }

      @include bp(xxxs-max) {
        word-break: break-word;
      }

      .dark-theme & {
        color: var(--title-dark-text);
      }

      .copy-icon {
        font-size: 16px !important;
        color: var(--primary-color);
      }

      .feedback-slider-wrap {
        max-width: 400px;
        width: 100%;
        margin: 0 0 15px;

        .slider-title {
          display: block;
          padding: 0 0 5px;
        }

        .p-progressbar {
          height: 16px;
          background-color: #c5bfff;
          border-radius: 15px;

          .p-progressbar-label {
            font-size: 12px;
            line-height: 16px;
            color: var(--surface-a);
          }
        }
      }
    }
  }
}
.avtar-wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  .hr-avatar {
    display: inline-flex;
    img {
      width: 35px;
      height: 35px;
    }
  }
  .name {
    font-weight: 500;
  }
}

.interview-details-dialog {
  width: 100%;
  max-width: 718px;

  .content-wrap {
    padding-bottom: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
}

// Feedback popup css
.feedback-dialog {
  max-width: 615px;
  width: 100%;
}

.form-box-wrapper {
  .form-row-wrapper {
    .form-col {
      p {
        color: #333;
        font-size: 12px;
        line-height: 1.18;
        font-family: 'Public Sans', sans-serif;
        padding: 5px 0 0;

        .dark-theme & {
          color: var(--title-dark-text);
        }
      }
    }
  }

  &.feedback-form-popup {
    .form-row-wrapper {
      padding: 0 0 24px;
      margin: 0 -12px -20px;

      @include bp(smd-max) {
        padding: 0 0 15px;
      }
    }

    .p-dialog-footer {
      margin: 0 -24px -20px;

      @include bp(smd-max) {
        margin: 0 -15px -15px;
      }
    }

    .p-slider {
      background-color: #c5bfff;
      border-radius: 5px;
      height: 5px;
      margin-bottom: 8px;

      .p-slider-range {
        border-radius: 5px;
        background-color: var(--primary-color);
      }

      .p-slider-handle {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border: 4px solid #fff;
        height: 24px;
        width: 24px;
        background-color: var(--primary-color);
        transform: translate(-50%, -50%);
        margin: 0;

        .dark-theme & {
          border-color: var(--title-dark-text);
        }
      }
    }
  }
}

// person details popup
.person-details-popup {
  max-width: 570px;
  width: 100%;

  .p-dialog-content {
    padding: 3px 20px 30px;
  }
}

// log-detail-popup
.log-detail-popup {
  .p-datatable.p-datatable-sm {
    .p-datatable-header {
      padding-top: 5px;
      padding-left: 0;
    }
  }

  .custom-table-wrapper {
    box-shadow: none;
    border: 1px solid #ececec;

    .dark-theme & {
      border-color: var(--border-light-color);
    }
  }
}

// availability-detail-popup
.availability-detail-popup {
  @include bp(sm-max) {
    width: 98% !important;
    padding: 0 !important;
  }

  .p-dialog-content {
    overflow-x: initial;
    overflow-y: initial;

    .card {
      padding: 0;
      // @include bp(sm-max){
      //   padding: 0;
      // }
    }

    .calendar-wrap {
      .fc-theme-standard {
        .fc-scrollgrid {
          @include bp(smd-max) {
            min-width: 718px;
          }
        }
      }
    }

    .p-tabview {
      .p-tabview-panels {
        @include bp(sm-max) {
          padding: 25px 0;
        }
      }

      .p-tabview-nav-container {
        .p-tabview-nav-content {
          @include bp(sm-max) {
            margin: 0;
          }
        }
      }
    }
  }

  .p-datatable-header {
    .table-header {
      .p-input-icon-left.search-input {
        .p-inputtext {
          width: 300px;

          @include bp(xxs-max) {
            width: 100%;
          }
        }
      }
    }
  }

  .custom-table-wrapper {
    box-shadow: none;
    border: 1px solid #ececec;

    .dark-theme & {
      border-color: var(--border-light-color);
    }
  }
}

.notesWrap {
  .notesListWrap {
    overflow-x: auto;
  }

  .MuiList-root {
    .MuiListItem-root {
      padding: 5px 60px 5px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ececec;

        .dark-theme & {
          border-color: var(--border-light-color);
        }
      }

      .MuiTypography-root {
        font-size: 13px !important;
        font-family: 'Public Sans', sans-serif;
        letter-spacing: 0;
      }
      .MuiListItemText-root {
        padding-right: 5px;
        width: 150px;
        &:first-child {
          width: calc(100% - 370px);
        }
      }
    }
  }
}

.dropdown_menu {
  .MuiPopover-paper {
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    right: 195px;
    left: auto !important;
    .dark-theme & {
      background-color: var(--dark-boxes);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
    }

    @include bp(llg-max) {
      right: 165px;
    }

    @include bp(sm-max) {
      right: 57px;
    }

    @include bp(xs-max) {
      right: 30px;
    }

    @include bp(xxxs-max) {
      right: 15px;
    }
  }

  .MuiMenu-list {
    padding: 0 0 0px !important;
  }

  .heading {
    padding: 15px !important;
    border-bottom: 2px solid var(--surface-h);
    .dark-theme & {
      border-color: var(--border-light-color);
    }
    h2 {
      color: var(--heading-text);
      font-size: 18px;
      line-height: 1.2;
      font-weight: 400;
      margin: 0;
      .dark-theme & {
        color: var(--title-dark-text);
      }
    }

    .clear-all {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }

  .notification-modal {
    @include bp(xs-max) {
      max-width: 320px;
      min-width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
      position: relative;
      right: 8px;
      padding-right: 8px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--gray-400);
      opacity: 0.8;
      max-height: 100px;
    }

    li {
      padding: 15px;
      border-left: 3px solid transparent;
      &:not(:last-child) {
        border-bottom: 2px solid rgba(228, 230, 244, 0.38);
        .dark-theme & {
          border-bottom-color: var(--border-light-color);
        }
      }
      &.unread {
        background: #f6f6ff;
        .dark-theme & {
          background: var(--surface-body);
        }
      }
      &.new-invitation {
        border-left-color: #208fc7;
      }
      &.rescheduled {
        border-left-color: #44d7d7;
      }
      &.cancelled {
        border-left-color: #b73eb2;
      }
      &.accepted {
        border-left-color: #7367f0;
      }
      &.rejected {
        border-left-color: #e76169;
      }
      &.completed {
        border-left-color: #46c96d;
      }
      &.reminder {
        border-left-color: #f9a147;
      }
      &.feedback-reminder {
        border-left-color: #f0dd30;
      }
    }

    .user-info {
      .user-data {
        padding: 0;
        h4 {
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 10px;
          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
        p {
          font-size: 13px;
          line-height: 17px;
          margin-bottom: 8px;
          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
        span {
          font-size: 11px;
          color: #666666;
          .dark-theme & {
            color: var(--title-dark-text);
          }
        }
      }
    }
  }

  .all-read {
    padding: 10px 15px;
    border-top: 2px solid #eeeeee;
    .dark-theme & {
      border-top-color: var(--border-light-color);
    }
    a {
      text-decoration: none;
      color: var(--heading-text);
      font-size: 12px;
      font-family: 'Public Sans', sans-serif;
      .dark-theme & {
        color: var(--title-dark-text);
      }
      &:hover {
        color: #9085ff;
      }
    }
  }
}
