.sidebar-open {
  overflow: hidden;
}
.p-sidebar-mask.p-component-overlay.p-sidebar-right {
  background: rgba(12, 12, 12, 0.13) !important;
  pointer-events: none;
  .dark-theme & {
    background: rgba(12, 12, 12, 0.4) !important;
  }
}
.p-sidebar-right {
  .p-sidebar {
    &.sidebar-drawer {
      width: 470px;
      box-shadow: none;
      border-radius: 0;
      @include bp(sm-max) {
        border-radius: 0;
      }
      &:before {
        position: absolute;
        content: '';
        background-image: url('../../images/top-curve-white.svg');
        width: 50px;
        height: 50px;
        background-size: 50px;
        left: 0;
        top: 0;
        z-index: 3;
        @include bp(sm-max) {
          display: none;
        }
        .dark-theme & {
          background-image: url('../../images/top-curve-dark-theme.svg');
        }
      }
      &:after {
        position: absolute;
        content: '';
        background-image: url('../../images/bottom-curve-white.svg');
        width: 50px;
        height: 50px;
        background-size: 50px;
        left: 0;
        bottom: 0;
        z-index: 3;
        @include bp(sm-max) {
          display: none;
        }
        .dark-theme & {
          background-image: url('../../images/bottom-curve-dark-theme.svg');
        }
      }
      @include bp(xxs-max) {
        width: 100%;
      }
      .dark-theme & {
        background-color: var(--dark-boxes);
      }
      .p-sidebar-header {
        display: none;
      }
      .p-sidebar-content {
        padding: 0;
        .title-wrapper {
          padding: 28px 30px 5px;
          background: transparent;
          position: absolute;
          z-index: 22;
          left: 0;
          right: 0;
          margin: 0;
          top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include bp(sm-max) {
            padding: 20px 15px 5px;
          }
          .card-title {
            margin-bottom: 0;
            @include bp(sm-max) {
              font-size: 18px;
            }
          }
          .p-sidebar-close {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            cursor: pointer;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
            &:hover {
              background: var(--rgba1) !important;
            }
            .dark-theme & {
              &:hover {
                background-color: var(--link-hover-bg) !important;
              }
            }
            @include bp(sm-max) {
              width: 27px;
              height: 27px;
            }
            .pi {
              color: var(--text-color);
              font-weight: 700;
              .dark-theme & {
                color: var(--title-dark-text);
              }
              @include bp(sm-max) {
                font-size: 14px;
              }
            }
          }
        }
        .form-box-wrapper {
          padding-top: 63px;
          @include bp(sm-max) {
            padding-top: 55px;
          }
          form {
            padding: 23px 30px 100px;
            height: calc(100vh - 63px);
            overflow-y: auto;
            @include bp(sm-max) {
              padding: 15px 15px 100px;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 5px;
              position: relative;
              right: 8px;
              padding-right: 8px;
            }
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-color: var(--gray-400);
              opacity: 0.8;
              max-height: 100px;
            }
          }
        }
        .form-btn-wrapper {
          padding: 15px 30px;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background: #fff;
          z-index: 11; 
          margin: 24px 0 0; 
          @include bp(sm-max) {
            padding: 15px;
            gap: 10px;
          }
          .dark-theme & {
            background: var(--dark-boxes);
          }
          button {
            padding: 0 17px;
            margin-bottom: 0 !important;
            &.gray-btn {
              background-color: var(--white);
              border: 1px solid var(--light-gray1);
              color: var(--heading-text);
              box-shadow:  none !important;
              &:hover {
                background-color: var(--secondary-btn-bg);
              }
              .dark-theme & {
                color: #e4e6f4;
                border-color: #2c2a40;
                &:hover {
                  background-color: var(--secondary-btn-bg);
                  color: var(--surface-a);
                  border-color: var(--secondary-btn-bg);
                }
              }
            }
          }
        }
      }
    }
  }
}
