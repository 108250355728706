.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 82px 24px 50px 24px;
  transition: margin-left $transitionDuration;
  margin-left: 260px;
  .sidebar-hide & {
    @include bp(smd-min-2) {
      margin-left: 80px !important;
    }
  }
  @include bp(llg-max) {
    padding: 80px 24px 24px;
  }
  // @include bp(lmd-max){
  //   padding: 90px 30px 30px 30px;
  // }
  // border-top-left-radius: 20px;
  // border-bottom-left-radius: 20px;
  // background-color: var(--surface-g);
  // overflow: auto;
  // position: relative;
  // @include bp(xxxl-min) {
  //   padding: 103px 100px 10px;
  // }
  // &:after{
  //   content: '';
  //   height: 100px;
  //   width: 100px;
  //   border-radius: 35px;
  //   position: fixed;
  //   top: 0;
  //   left: 260px;
  //   background-color: #fff;
  //   display: block;
  //   z-index: 100;
  // }
  // .dark-theme & {
  //   background-color: var(--surface-body);
  //   color: var(--title-dark-text);
  // }
  &:after {
    content: '';
    height: 50px;
    background-color: var(--surface-h);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    left: 260px;
    display: none;
    .sidebar-hide & {
      @include bp(smd-min-2) {
        left: 91px;
      }
    }
    @include bp(llg-max) {
      height: 24px;
    }
    @include bp(smd-max) {
      width: 100%;
      display: none;
    }
    .dark-theme & {
      background-color: var(--surface-body);
    }
  }
  @include bp(smd-max) {
    padding: 64px 15px 10px;
  }
  .mobile-portal-title {
    color: var(--surface-900);
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    padding: 20px 0;
    display: none;
    text-transform: capitalize;
    @include bp(smd-max) {
      display: block;
    }
    .dark-theme & {
      color: var(--title-dark-text);
    }
  }
}

.layout-main {
  flex: 1 1 auto;
}

.dark-theme-logo {
  display: none;
  .dark-theme & {
    display: block;
  }
}

.light-theme-logo {
  display: block;
  .dark-theme & {
    display: none;
  }
}

.p-tooltip {
  .p-tooltip-text {
    padding: 6px 8px !important;
    font-size: 14px;
    white-space: nowrap;
    word-break: normal;
    // white-space: normal;
  }
}

.__react_component_tooltip {
  white-space: nowrap !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: var(--surface-body) !important;
  border-radius: 5px !important;
  font-weight: 400;
  &::after {
    background-color: var(--surface-body) !important;
  }
}
.rbc-today {
  background-color: #ebe9ff !important;
}
.rbc-day-slot.rbc-today .rbc-time-slot {
  border-top: 1px solid #d5d4e4;
}

.p-badge {
  font-size: 12px !important;
  font-weight: 400;
  padding: 5px 8px;
  min-width: auto;
  border-radius: 3px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #fff;
  text-transform: capitalize;
  &.p-badge-info {
    background-color: #d4e4fa;
    color: #0d4ea6;
  }
  &.p-badge-success {
    background-color: #c7ebd1;
    color: #075e45;
  }
  &.p-badge-danger {
    background-color: #fadcd9;
    color: #a1160b;
  }
  &.p-badge-warning {
    background-color: #faf6cf;
    color: #1c2b36;
  }
  // .p-badge.p-badge-secondary {
  //     background-color: #64748b !important;
  //     color: #ffffff !important;
  // }
}

.primary-color {
  color: var(--primary-color);
}

// body, body *{
//     transition: all 0.2s !important;
// }
.fade-appear .layout-main-container,
.fade-enter .layout-main-container {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active .layout-main-container,
.fade-enter.fade-enter-active .layout-main-container {
  opacity: 1;
  transition: opacity 400ms linear 200ms;
}

.fade-exit .layout-main-container {
  opacity: 1;
}

.fade-exit.fade-exit-active .layout-main-container {
  opacity: 0;
  transition: opacity 200ms linear;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.practical-admin {
  font-size: 10px !important;
  border-radius: 10px !important;
  padding: 4px 8px !important;
}
